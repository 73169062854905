import React, { useEffect, useState } from 'react'
import { CBackdrop, CConditionalPortal, CModal, CModalBody, CModalHeader } from '@coreui/react-pro'
import PropTypes from 'prop-types'

const ModalMessage = ({ title, message, children, component, ...rest }) => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    setIsVisible(message || false)
  }, [message])

  useEffect(() => {
    if (isVisible) {
      document.body.style.overflow = 'hidden'
      document.body.style.paddingRight = '0px'
    }
  }, [isVisible])

  return (
    <>
      <CConditionalPortal portal={true}>
        <CBackdrop visible={isVisible} style={{ zIndex: 1060 }} />
      </CConditionalPortal>
      <CModal
        visible={isVisible}
        backdrop={false}
        className="mt-3"
        style={{ display: 'block', zIndex: 1060 }}
        {...rest}
      >
        <CModalHeader closeButton>
          <b>{title}</b>
        </CModalHeader>
        <CModalBody style={{ whiteSpace: 'pre-wrap' }}>
          {message}
          {component}
        </CModalBody>
        {children}
      </CModal>
    </>
  )
}

ModalMessage.propTypes = {
  title: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  children: PropTypes.node,
  component: PropTypes.element,
}
export default React.memo(ModalMessage)
