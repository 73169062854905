import { useAuth } from './useAuth'

export const useApi = () => {
  const { user } = useAuth()

  const configApiDomain = process.env.REACT_APP_API_DOMAIN

  /**
   * Подстановочный параметр:
   *   %PATH% - из config.path
   */
  const endpoint = {
    ACCOUNT: '/account',
    ACCOUNT_ENUM: '/account/enum',
    ACCOUNT_FIND: '/account/find',
    ACCOUNT_LIST: '/account/list',
    ACCOUNT_PASSWORD: '/account/password',
    CERTIFICATE: '/certificate',
    CERTIFICATE_FREE_NUMBER: '/certificate/free_number',
    CERTIFICATE_LIST: '/certificate/list',
    CERTIFICATE_SERIES_ENUM: '/certificate_series/enum',
    CONTACT: '/contact',
    CONTACT_LIST: '/contact/list',
    CONTACT_PATH_LIST: `/contact/%PATH%/list`,
    COURSE: '/course',
    COURSE_LIST: '/course/list',
    CITY: '/city',
    CITY_LIST: '/city/list',
    DATA1C_ORDER_DELIVERYSERVICE_OPTION: '/data_1c/order/delivery_service/option',
    DATA1C_ORDER_DELIVERYTYPE_OPTION: '/data_1c/order/delivery_type/option',
    DATA1C_ORDER_STATUS_OPTION: '/data_1c/order/status/option',
    DATA1C_SALESORDERHEADER: '/data_1c/sales_order_header',
    DATA1C_SALESORDERHEADER_LIST: '/data_1c/sales_order_header/list',
    DATA1C_SALESORDERITEMS: '/data_1c/sales_order_items',
    INSTRUCTOR: '/instructor',
    INSTRUCTOR_ENUM: '/instructor/enum',
    INSTRUCTOR_STATUS_ENUM: '/instructor/status/enum',
    INSTRUCTOR_LIST: '/instructor/list',
    MASTER: '/master',
    MASTER_ENUM: '/master/enum',
    MASTER_GROUP_ENUM: '/master/group/enum',
    MASTER_LIST: '/master/list',
    PARENT_ENUM: '/parent/enum',
    PICTURE: '/picture',
    REPORT_INSTRUCTOR: '/report/instructor',
    REPORT_PARENT: '/report/parent',
    ROLE_ENUM: '/role/enum',
    SEMINAR: '/seminar',
    SEMINAR_LIST: '/seminar/list',
    TEAM: '/team',
    TEAM_LIST: '/team/list',
    VKPHOTO: '/vk_photo',
  }

  /**
   * HTTP запрос с заголовком авторизации, получение объекта или blob
   *
   * @param method - get, post, put, delete
   * @param endpoint - относительный путь на сервере API
   * @param param - query-параметры запроса
   * @param config - дополнительные параметры запроса
   *          path - подстановка в путь на сервере
   *          body - тело запроса
   *          responseType = blob - получение blob
   *          onError - пользовательское действие при ошибке
   * @returns {Promise<any|{}|Blob>}
   *          при ошибке reject текста ошибки
   */
  const request = async (method, endpoint, param = null, config = {}) => {
    const options = {
      method: method,
      headers: new Headers(),
    }
    if (user && user.hasOwnProperty('accessToken')) {
      options.headers.append('Authorization', 'Bearer ' + user.accessToken)
    }
    if (config.hasOwnProperty('body') && config.body) {
      if (typeof config.body === 'object' && !(config.body instanceof FormData)) {
        options.headers.append('Content-Type', 'application/json;charset=utf-8')
        options.body = JSON.stringify(config.body)
      } else options.body = config.body
    }
    const url =
      configApiDomain +
      endpoint.replace('%PATH%', (config && config.path) || '') +
      (param && Object.keys(param).length > 0 ? '?' + new URLSearchParams(param).toString() : '')
    try {
      const response = await fetch(url, options)
      if (response.ok) {
        let resData
        if (config.responseType === 'blob') {
          const blob = await response.blob()
          if (blob.type == 'application/json') {
            resData = JSON.parse(await blob.text())
          } else return blob
        } else resData = await response.json()
        if (resData.error === 0) {
          return resData.data
        } else {
          if (config.onError) {
            config.onError()
            return Promise.reject()
          } else {
            return Promise.reject(resData.message || `Ошибка запроса ${resData.error}`)
          }
        }
      } else {
        if (config.onError) {
          config.onError()
          return Promise.reject()
        } else {
          const msg = await response.text()
          return Promise.reject(msg || `Ошибка запроса ${response.status}`)
        }
      }
    } catch (e) {
      if (config.onError) config.onError()
      return Promise.reject('Общая ошибка запроса')
    }
  }

  const get = (endpoint, param = null, config = {}) => {
    return request('get', endpoint, param, config)
  }

  const post = (endpoint, param = null, config = {}) => {
    return request('post', endpoint, param, config)
  }

  const del = (endpoint, param = null, config = {}) => {
    return request('delete', endpoint, param, config)
  }

  const getPicture = (filter) => {
    return new Promise((resolve, reject) => {
      request('get', endpoint.PICTURE, filter, {
        responseType: 'blob',
        onError: () => {},
      })
        .then((data) => {
          const urlCreator = window.URL || window.webkitURL
          const imageUrl = urlCreator.createObjectURL(data)
          resolve(imageUrl)
        })
        .catch(() => resolve(null))
    })
  }

  return {
    endpoint,
    get,
    post,
    del,
    getPicture,
  }
}
