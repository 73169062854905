/* eslint-disable react/prop-types */
import React, { createContext, useContext, useMemo } from 'react'
import { useLocalStorage } from './useLocalStorage'
import { useNavigate } from 'react-router-dom'

const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage('user', null)
  const navigate = useNavigate()
  const configApiDomain = process.env.REACT_APP_API_DOMAIN
  const Roles = {
    ADMIN: 'ADMIN',
    INSTRUCTOR: 'INSTRUCTOR',
    MASTER: 'MASTER',
    USER: 'USER',
    PARENT: 'PARENT',
    SALES: 'SALES',
    MARKETING: 'MARKETING',
  }

  const login = async (data) => {
    try {
      const response = await fetch(configApiDomain + '/auth/signin', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      })
      if (response.ok) {
        const resData = await response.json()
        if (resData.error === 0) {
          const data = resData.data
          if (data.hasOwnProperty('accessToken')) {
            const base64Url = data.accessToken.split('.')[1]
            const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
            const jsonPayload = decodeURIComponent(
              window
                .atob(base64)
                .split('')
                .map(function (c) {
                  return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
                })
                .join(''),
            )
            const user = JSON.parse(jsonPayload).user
            user.accessToken = data.accessToken
            setUser(user)
            navigate('/')
          }
        } else {
          return Promise.reject(resData.message || `Ошибка запроса ${resData.error}`)
        }
      } else {
        const msg = await response.text()
        return Promise.reject(msg || `Ошибка запроса ${response.status}`)
      }
    } catch (e) {
      return Promise.reject('Ошибка запроса')
    }
  }

  const logout = () => {
    setUser(null)
    navigate('/login', { replace: true })
  }

  const checkRoles = (roles, checkUser) => {
    const us = checkUser || user
    let check = us && us.hasOwnProperty('roles') && us.roles.length > 0 && roles && roles.length > 0
    if (!check) return false
    check = false
    if (typeof roles === 'string') check = us.roles.includes(roles)
    else
      roles.forEach((it) => {
        check = check || us.roles.includes(it)
      })
    return check
  }

  const value = useMemo(
    () => ({
      user,
      setUser,
      login,
      logout,
      Roles,
      checkRoles,
    }),
    [user],
  )

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
  return useContext(AuthContext)
}
