import React, { Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { CContainer, CSpinner } from '@coreui/react-pro'

// routes config
import routes from '../routes'
import AppBreadcrumb from './AppBreadcrumb'
import { useAuth } from '../hooks/useAuth'

const AppContent = () => {
  const { Roles, checkRoles } = useAuth()
  const roles = [
    { role: Roles.ADMIN, route: routes.ADMIN },
    { role: Roles.PARENT, route: routes.PARENT },
    { role: Roles.SALES, route: routes.SALES },
    { role: Roles.INSTRUCTOR, route: routes.INSTRUCTOR },
    { role: Roles.MARKETING, route: routes.MARKETING },
  ]

  const userRoutes = roles.find(({ role }) => checkRoles(role))?.route || []

  return (
    <CContainer fluid className="px-3">
      <AppBreadcrumb userRoutes={userRoutes} />
      <Suspense fallback={<CSpinner color="primary" />}>
        <Routes>
          {userRoutes.map((route, idx) => {
            return (
              route.element && (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  element={<route.element />}
                />
              )
            )
          })}
          <Route path="/" element={<Navigate to="dashboard" replace />} />
        </Routes>
      </Suspense>
    </CContainer>
  )
}

export default React.memo(AppContent)
